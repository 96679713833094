import React, { useState, useEffect, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

const GlobalStateVariables = React.createContext();

export const GlobalStateProvider = ({ children }) => {
    
    const navigate = useNavigate();
    const isIphone = /iPhone/i.test(navigator.userAgent);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isMobile = useMediaQuery({ query: '(max-width: 820px)' });
    const [color, setColor] = useState('');
    const [deviceIsOpen, setDeviceIsOpen] = useState(null);
    const [hoveredLight, setHoveredLight] = useState(null);

    const [isFirstLoad, setIsFirstLoad] = useState(false);
    const [globalTapPrompt, setGlobalTapPrompt] = useState(false);

    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(true);
    const [showVideoProgressBar, setShowVideoProgressBar] = useState(true);
    const [showCaptions, setShowCaptions] = useState(true);
    const [skipVideo, setSkipVideo] = useState(false);
    const [videoSrc, setVideoSrc] = useState('');

    const [visitCount, setVisitCount] = useState(() => {
        // Initialize visitCount from sessionStorage if available, otherwise default to 1
        return parseInt(sessionStorage.getItem('visit_count')) || 1;
    });

    // Helper function to advance the visit count
    const advanceVisitCount = () => {
        const newCount = visitCount === 3 ? 1 : visitCount + 1;
        setVisitCount(newCount);
        sessionStorage.setItem('visit_count', newCount.toString());
    };

    // Helper function to calculate the luminance of a color
    const luminance = (r, g, b) => {
        const a = [r, g, b].map((v) => {
            v /= 255;
            return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
        });
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    };

    // Helper function to calculate the contrast ratio between two colors
    const contrastRatio = (rgb1, rgb2) => {
        const lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
        const lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
        return lum1 > lum2 ? (lum1 + 0.05) / (lum2 + 0.05) : (lum2 + 0.05) / (lum1 + 0.05);
    };

    // Set text color based on the background color contrast to stay compliant
    const setTextColor = (r, g, b) => {
        const whiteContrast = contrastRatio([r, g, b], [255, 255, 255]);
        const blackContrast = contrastRatio([r, g, b], [0, 0, 0]);

        // WCAG minimum contrast ratio is 4.5:1 for normal text
        return whiteContrast >= 4.5 ? 'white' : 'black';
    };

    //Pick a random color
    useEffect(() => {
        const generateRandomColor = () => {
            // Generate random values for hue (0-360), saturation (60-100%), and brightness (50-100%)
            const h = Math.floor(Math.random() * 361);
            const s = Math.floor(Math.random() * 41) + 60;
            const v = Math.floor(Math.random() * 51) + 50;
    
            // Convert HSB/HSV values to RGB
            const hsvToRgb = (h, s, v) => {
                s /= 100; // Convert saturation to a fraction
                v /= 100; // Convert brightness to a fraction
                let r, g, b;
    
                const i = Math.floor(h / 60); // Sector 0 to 5
                const f = h / 60 - i; // Fractional part of h
                const p = v * (1 - s);
                const q = v * (1 - f * s);
                const t = v * (1 - (1 - f) * s);
    
                switch (i) {
                    case 0: r = v, g = t, b = p; break;
                    case 1: r = q, g = v, b = p; break;
                    case 2: r = p, g = v, b = t; break;
                    case 3: r = p, g = q, b = v; break;
                    case 4: r = t, g = p, b = v; break;
                    case 5: r = v, g = p, b = q; break;
                    default: r = 0, g = 0, b = 0;
                }
    
                return [Math.floor(r * 255), Math.floor(g * 255), Math.floor(b * 255)];
            };
    
            const [r, g, b] = hsvToRgb(h, s, v);

            // Calculate hover color by making it 20% lighter
            const hoverR = Math.min(255, Math.floor(r + (255 - r) * 0.4));
            const hoverG = Math.min(255, Math.floor(g + (255 - g) * 0.4));
            const hoverB = Math.min(255, Math.floor(b + (255 - b) * 0.4));
            const hoverColor = `rgb(${hoverR}, ${hoverG}, ${hoverB})`;

            // Calculate the appropriate text color (white or black) based on contrast ratio
            const textColor = setTextColor(r, g, b);
    
            // Create the color string in RGB format and set both background and text colors
            const randomColor = `rgb(${r}, ${g}, ${b})`;
            setColor(randomColor);
    
            // Apply the color to the question buttons in CSS or inline styles
            document.documentElement.style.setProperty('--global-color', randomColor);
            document.documentElement.style.setProperty('--text-color', textColor);
            document.documentElement.style.setProperty('--hover-color', hoverColor);
        };
    
        generateRandomColor();
    }, []);

    useEffect(() => {
        const hasNavigated = sessionStorage.getItem('has_navigated');
        if (!hasNavigated) {
            const introNotAllowed = localStorage.getItem('turn_off_intro_permenantly');
            const isSessionNew = sessionStorage.getItem('is_session_new');

            if (isSessionNew === 'no' || introNotAllowed === 'true') {
                navigate('/');
            } else {
                setIsFirstLoad(true);
                navigate('/intro');
            }

            sessionStorage.setItem('has_navigated', 'true');
        }
    }, []);

    useEffect(() => {
        console.log('isPlaying state changed:', isPlaying);
    }, [isPlaying]);

    useEffect(() => {
        console.log('isMuted state changed:', isMuted);
    }, [isMuted]);
    
    useEffect(() => {
        if (isMobile) {
            // Attempt to lock the screen orientation to portrait (both primary and secondary)
            if (screen.orientation && screen.orientation.lock) {
                screen.orientation.lock('portrait').catch((err) => {
                    console.warn('Screen orientation lock not supported or failed:', err);
                });
            } else {
                console.warn('Screen orientation lock not supported by this browser/device.');
            }
        }
    }, [isMobile]);

    return (
        <GlobalStateVariables.Provider value={{
            isSafari,
            isIphone,
            isMobile,
            isFirstLoad,
            setIsFirstLoad,
            isMuted,
            setIsMuted,
            isPlaying,
            setIsPlaying,
            showVideoProgressBar,
            setShowVideoProgressBar,
            showCaptions,
            setShowCaptions,
            skipVideo,
            setSkipVideo,
            videoSrc,
            setVideoSrc,
            deviceIsOpen,
            setDeviceIsOpen,
            globalTapPrompt,
            setGlobalTapPrompt,
            color,
            hoveredLight,
            setHoveredLight,
            visitCount,
            advanceVisitCount
        }}>
            {children}
        </GlobalStateVariables.Provider>
    );
};

// Custom hook to access the global state
export const useGlobalState = () => useContext(GlobalStateVariables);
