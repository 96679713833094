import '../../../styles/apps/music.css';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Howl } from 'howler';
import Header from '../app elements/AppHeader.jsx'; // Importing Header

export default function Music() {
    const navigate = useNavigate();
    const [currentSong, setCurrentSong] = useState(null);
    const [songIsPlaying, setSongIsPlaying] = useState(false); 
    const [totalTime, setTotalTime] = useState('0:00');
    const [volume, setVolume] = useState(1.0);
    const [selectedSong, setSelectedSong] = useState(null);

    // Ref to track whether the song has already been played
    const hasPlayedRef = useRef(false);

    const songsJSON = [
        { id: 1, title: "Arabesque No. 1", artist: "Claude Debussy", recorded: "December 18, 2016", file: './mobileAssets/music/ArabesqueNo1.mp3' },
        { id: 2, title: "Gnossienne No. 1", artist: "Erik Satie", recorded: "November 18, 2016", file: './mobileAssets/music/Gnossienne.mp3' },
        { id: 3, title: "Bethena: A Concert Waltz", artist: "Scott Joplin", recorded: "December 15, 2016", file: './mobileAssets/music/BethenaConcertWaltz.mp3' },
        { id: 4, title: "The Man I Love", artist: "George Gershwin", recorded: "December 17, 2016", file: './mobileAssets/music/TheManILove.mp3' },
        { id: 5, title: "O That Will Be Glory", artist: "Hymn arr. by Cindy Berry", recorded: "October 9, 2016", file: './mobileAssets/music/OThatWillBeGlory.mp3' }
    ];

    // Function to set up the audio visualizer
    const setupVisualizer = () => {
        const audioContext = Howler.ctx; // Access the Howler.js audio context
        const analyserNode = audioContext.createAnalyser(); // Create an analyser node
        analyserNode.fftSize = 256; // Set FFT size (number of data points)
        Howler.masterGain.connect(analyserNode); // Connect analyser to the Howler master gain node

        const bufferLength = analyserNode.frequencyBinCount; // Get the buffer length
        const dataArray = new Uint8Array(bufferLength); // Create a buffer array to store frequency data

        animateVisualizer(analyserNode, dataArray); // Start the animation
    };

    // Function to animate the visualizer
    const animateVisualizer = (analyser, dataArray) => {
        const canvas = document.getElementById('music-visualizer');
        const ctx = canvas.getContext('2d');

        const renderFrame = () => {
            requestAnimationFrame(renderFrame); // Call the function repeatedly
            analyser.getByteFrequencyData(dataArray); // Get frequency data from the analyser
            ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas
            const barWidth = (canvas.width / dataArray.length) * 2.5; // Calculate bar width
            let barHeight;
            let x = 0;

            // Draw bars for each frequency bin
            for (let i = 0; i < dataArray.length; i++) {
                barHeight = dataArray[i] / 2; // Set bar height based on frequency

                const red = barHeight + 100;
                const green = 50;
                const blue = 150 + (i * 2); // Dynamic color based on frequency

                ctx.fillStyle = `rgb(${red}, ${green}, ${blue})`;
                ctx.fillRect(x, canvas.height - barHeight, barWidth, barHeight);

                x += barWidth + 1;
            }
        };

        renderFrame(); // Start rendering the animation frames
    };

    // Properly play the selected song, ensuring only one instance is playing
    const playSong = (song) => {
        console.log("PLAY SONG...");

        if (currentSong) {
            currentSong.stop();  // Stop the existing Howl instance
            setCurrentSong(null);  // Clear current song reference
        }

        const sound = new Howl({
            src: [song.file],
            volume: volume,
            onplay: () => {
                setupVisualizer();  // Set up visualizer once the song starts playing
            },
            onend: () => setSongIsPlaying(false),
        });

        setCurrentSong(sound);  // Update the currentSong state immediately
        setSelectedSong(song);  // Set the selected song
        sound.play();  // Start playing the song
        setSongIsPlaying(true);  // Update the songIsPlaying state
    };

    // Automatically play the first song when the component loads, but only once
    useEffect(() => {
        console.log("onLoad useEffect triggered");

        if (!hasPlayedRef.current && !selectedSong && songsJSON.length > 0) {
            console.log("No selected song, starting the first song");
            const firstSong = songsJSON[0];
            playSong(firstSong);  // Play the first song immediately
            hasPlayedRef.current = true;  // Mark that the song has been played
        }

        console.log("onLoad useEffect ended");
    }, []);  // The empty array ensures the effect runs only once when the component mounts

    // Function to toggle between play and pause
    const togglePlayPause = () => {
        if (currentSong) {
            if (currentSong.playing()) {
                currentSong.pause();
                setSongIsPlaying(false);
            } else {
                currentSong.play();
                setSongIsPlaying(true);
            }
        }
    };

    // Function to stop the current song
    const stopSong = () => {
        if (currentSong) {
            currentSong.stop();
            setSongIsPlaying(false);
        }
    };

    // Function to handle timeline updates
    const handleTimelineChange = (event) => {
        if (currentSong) {
            const newTime = currentSong.duration() * (parseFloat(event.target.value) / 100);
            currentSong.seek(newTime);
        }
    };

    // Handle song selection from the playlist
    const handleSongButtonClick = (song) => {
        if (selectedSong && selectedSong.id === song.id) {
            // If the same song is clicked, toggle play/pause
            if (songIsPlaying) {
                stopSong();
            } else {
                playSong(song);
            }
        } else {
            // Stop the current song if it's different, then play the new one
            if (currentSong) {
                currentSong.stop();
                setCurrentSong(null);  // Set currentSong to null immediately to avoid delays
            }
            playSong(song);  // Play the new song right away
        }
    };

    // Helper function to format time
    const getFormattedTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    // Update the timeline as the song plays
    useEffect(() => {
        if (!currentSong) return;

        const timer = setInterval(() => {
            const currentTime = currentSong.seek() || 0;
            setTotalTime(getFormattedTime(currentSong.duration() - currentTime));
            document.getElementById('timeline').value = (currentTime / currentSong.duration()) * 100;
        }, 1000);

        return () => clearInterval(timer);
    }, [currentSong]);

    // Handle closing the app and stopping the current song
    const handleCloseApp = () => {
        navigate(-1);
        if (currentSong) {
            currentSong.stop();  // Stop the song when the app is closed
        }
        setCurrentSong(null);  // Clear the current song reference
    };

    return (
        <div className="app-container">
            <div className="app-header">
                <Header title="Music" />
                <button className="mobile-button" onClick={handleCloseApp}>Close App</button>
            </div>
            <div className="app-contents">
                {/* Canvas for the visualizer */}
                <canvas id="music-visualizer" width="800" height="300"></canvas>

                <div id="song-info">
                    <h2>{selectedSong?.title || 'Select a song'}</h2>
                    {selectedSong && (
                        <>
                            <p>Artist: {selectedSong.artist}</p>
                            <p>Jeffrey recorded this on {selectedSong.recorded}</p>
                        </>
                    )}
                </div>

                <div className="side-by-side">
                    <button onClick={togglePlayPause}>
                        {songIsPlaying ? 'PAUSE' : 'PLAY'}
                    </button>
                    <button id="stopButton" onClick={stopSong} disabled={!songIsPlaying}>
                        STOP
                    </button>
                </div>

                <div className="timeline-container">
                    <input
                        id="timeline"
                        type="range"
                        min="0"
                        max="100"
                        step="0.01"
                        onChange={handleTimelineChange}
                    />
                    <div id="total-time">{totalTime}</div>
                </div>

                <div> JEFFREY'S PLAYLIST </div>
                <div id="playlist">
                    {songsJSON.map(song => (
                        <button key={song.id} onClick={() => handleSongButtonClick(song)} className={selectedSong?.id === song.id && songIsPlaying ? 'accent' : ''}>
                            {song.title}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}
