import '../../../styles/apps/phone.css';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../app elements/AppHeader.jsx';
import CloseApp from '../app elements/CloseApp.jsx';
import { Howl } from 'howler';

export default function Phone() {

    const navigate = useNavigate();
    const [dialedNumbers, setDialedNumbers] = useState('');
    const [ringingSound, setRingingSound] = useState(null);
    const [speakingSound, setSpeakingSound] = useState(null);
    const [hiddenMessageSound, setHiddenMessageSound] = useState(null);
    const [shouldPlayDialTone, setShouldPlayDialTone] = useState(true);
    const isMounted = useRef(true);
    const numberDisplay = useRef();
    const numberKey = useRef([]);
    const secretButtonRef = useRef();
    const callBtnRef = useRef();
    const hangUpBtnRef = useRef();

    useEffect(() => {
        // Initially hide the secret button
        if (secretButtonRef.current) {
            secretButtonRef.current.style.display = 'none';
        }
    }, []);

    useEffect(() => {
        return () => {
            // Cleanup function to stop any playing sounds when the app closes
            console.log("phone has closed");
            if (ringingSound) ringingSound.stop();
            if (speakingSound) speakingSound.stop();
            if (hiddenMessageSound) hiddenMessageSound.stop();
            // Remove event listener to prevent memory leaks
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [ringingSound, speakingSound, hiddenMessageSound]);

    useEffect(() => {
        hangUpBtnRef.current.style.display = "none";

        const handleVisibility = () => {
            if (ringingSound || speakingSound || hiddenMessageSound) {
                callBtnRef.current.style.display = "none";
                hangUpBtnRef.current.style.display = "block";
            } else {
                callBtnRef.current.style.display = "block";
                hangUpBtnRef.current.style.display = "none";
            }
        };

        handleVisibility();
    }, [ringingSound, speakingSound, hiddenMessageSound]);

    const handleNumberKeyClick = (event) => {
        if (event.target.dataset.number && dialedNumbers.length < 10) {
            const number = event.target.dataset.number;
            setDialedNumbers(prev => {
                const updatedDialedNumbers = prev + number;
                return updatedDialedNumbers;
            });
            setShouldPlayDialTone(true);
        }
    };

    const updateDisplay = () => {
        if (dialedNumbers.length === 10) {
            callBtnRef.current.style.opacity = 1;
            callBtnRef.current.style.backgroundColor = '#4CAF50';
            callBtnRef.current.disabled = false;
        } else {
            callBtnRef.current.style.opacity = 0.5;
            callBtnRef.current.style.backgroundColor = '#666866';
            callBtnRef.current.disabled = true;
        }
        const formattedNumber = dialedNumbers.replace(/([\d*#]{1,3})([\d*#]{1,3})?([\d*#]{1,4})?/, function(match, p1, p2, p3) {
            let formatted = '';
            if (p1) formatted += `(${p1}`;
            if (p2) formatted += `)-${p2}`;
            if (p3) formatted += `-${p3}`;
            return formatted;
        });
        numberDisplay.current.textContent = formattedNumber;
    };

    const playDialTone = (number) => {
        const fileName = (number === '*') ? 'star' : (number === '#') ? 'pound' : number;
        const soundPath = `./mobileAssets/phoneSounds/${fileName}.mp3`;
        const dialToneSound = new Howl({
            src: [soundPath],
            volume: 1.0,
        });
        dialToneSound.play();
    };

    const handleSecretBtnClick = () => {
        window.open('https://www.introducingjeffrey.com/playground', '_blank');
    }

    const handleCallBtnClick = () => {
        const ringing = new Howl({
            src: ['../mobileAssets/phoneSounds/ringing-short.mp3'],
            volume: 1.0,
            onend: function () {
                if (dialedNumbers === '6012099949') {
                    const hiddenMessage = new Howl({
                        src: ['../mobileAssets/phoneSounds/hiddenMessage.mp3'],
                        volume: 1.0,
                        onend: () => resetPhoneUI(),
                    });
                    hiddenMessage.play();
                    setHiddenMessageSound(hiddenMessage);
                } else if (dialedNumbers === '3215550426') {
                    if (secretButtonRef.current) {
                        secretButtonRef.current.style.display = 'block';
                    }
                    resetPhoneUI()
                } {
                    const speakingMessage = new Howl({
                        src: ['../mobileAssets/phoneSounds/noanswer.mp3'],
                        volume: 1.0,
                        onend: () => resetPhoneUI(),
                    });
                    setSpeakingSound(speakingMessage);
                    speakingMessage.play();
                }
            }
        });
        window.addEventListener('beforeunload', handleBeforeUnload);
        if (!isMounted.current) return;
        setRingingSound(ringing);
        ringing.play();
    };

    const handleBeforeUnload = () => {
        console.log("Before unload event triggered");
        if (ringingSound) ringingSound.stop();
        if (speakingSound) speakingSound.stop();
        if (hiddenMessageSound) hiddenMessageSound.stop();
        window.removeEventListener('beforeunload', handleBeforeUnload);
    };

    const handleHangUpBtnClick = () => {
        console.log("handling hang up button click...");
        if (ringingSound) ringingSound.stop();
        if (speakingSound) speakingSound.stop();
        if (hiddenMessageSound) hiddenMessageSound.stop();
        resetPhoneUI();
    };

    const resetPhoneUI = () => {
        console.log("handling reset Phone UI");
        if (hangUpBtnRef.current) {
            hangUpBtnRef.current.style.display = "none";
        }
        if (callBtnRef.current) {
            callBtnRef.current.style.display = "block";
        }
        setDialedNumbers('');
        setShouldPlayDialTone(false);
    };

    useEffect(() => {
        updateDisplay();
        if (shouldPlayDialTone) {
            const lastDigit = dialedNumbers.slice(-1);
            playDialTone(lastDigit);
        }
    }, [dialedNumbers]);

    return (
        <div className="app-container">
            <header className="app-header">
                <Header title="Phone" />
                <CloseApp navigate={navigate} />
            </header>
            <main className="app-contents">
                <div ref={numberDisplay} className="phone-number-display"></div>
                <section ref={numberKey} onClick={handleNumberKeyClick} className="number-pad">
                    <div>
                        <button data-number="1">1</button>
                        <button data-number="2">2</button>
                        <button data-number="3">3</button>
                    </div>
                    <div>
                        <button data-number="4">4</button>
                        <button data-number="5">5</button>
                        <button data-number="6">6</button>
                    </div>
                    <div>
                        <button data-number="7">7</button>
                        <button data-number="8">8</button>
                        <button data-number="9">9</button>
                    </div>
                    <div>
                        <button data-number="*">*</button>
                        <button data-number="0">0</button>
                        <button data-number="#">#</button>
                    </div>
                </section>
                <div className="call-actions">
                    <div ref={secretButtonRef} id="secret-btn" style={{ display: 'none' }}>
                        Congrats! You unlocked a secret!
                    <button  onClick={handleSecretBtnClick} >
                        View Secret Project
                    </button>
                    </div>
                    <button ref={callBtnRef} onClick={handleCallBtnClick} id="call-btn">
                        Call
                    </button>
                    <button ref={hangUpBtnRef} onClick={handleHangUpBtnClick} id="hang-up-btn">
                        End Call
                    </button>
                </div>
            </main>
        </div>
    );
}
