import '../../../styles/contactStyles.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../app elements/AppHeader.jsx';
import CloseApp from '../app elements/CloseApp.jsx';
import ConfirmationPopup from '../../DeviceElements/EmailConfirmationPopup.jsx';
import { submitForm } from '../../DeviceElements/EmailFormSubmit.js';

const FORM_KEEP_URL = 'https://formkeep.com/f/d472b9a99186';

export default function Email() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        userEmail: '',
        message: '',
    });
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const maxLength = 350;
    const rows = Math.ceil(maxLength / 30);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        await submitForm(formData, setConfirmationVisible, FORM_KEEP_URL);
        setFormData({ name: '', userEmail: '', message: '' })
    };

    return (
        <div className="app-container">
            <header className="app-header">
                <Header title="Email Jeffrey" />
                <CloseApp navigate={navigate} />
            </header>
            <main className="app-contents">
                <form className="form-width" onSubmit={handleSubmit}>
                    <section>
                        <label>Your Name</label>
                        <input
                            type="text"
                            name="name"
                            maxLength={75}
                            value={formData.name}
                            onChange={handleChange}
                            className="form-input"
                        />
                    </section>
                    <section>
                        <label>Your Email *</label>
                        <input
                            type="email"
                            name="userEmail"
                            maxLength={100}
                            value={formData.userEmail}
                            onChange={handleChange}
                            className="form-input"
                        />
                    </section>
                    <section>
                        <div className="message-characters-label">
                            <label>Your Message *</label>
                            <span>{maxLength - formData.message.length} characters</span>
                        </div>
                        <textarea
                            name="message"
                            maxLength={maxLength}
                            value={formData.message}
                            onChange={handleChange}
                            rows={rows}
                            className="form-input"
                        />
                    </section>
                    <button
                        type="submit"
                        disabled={
                            !(formData.userEmail.includes('@') && formData.userEmail.includes('.') && formData.message.trim() !== '')
                        }
                        className="submit-button"
                    >
                        Send
                    </button>
                </form>
                
                {confirmationVisible && (
                    <ConfirmationPopup 
                        message="Thank you! Your message was successfully sent to Jeffrey." 
                        onClose={() => {
                            setConfirmationVisible(false)
                            navigate('/mobile')
                        }} 
                    />
                )}
            </main>
        </div>
    );
}
