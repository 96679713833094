import { GlobalStateProvider } from './environment/GlobalStateVariables.jsx'
import React from 'react'
import MainNav from './navigation/MainNav.jsx'
import Background from './environment/Background.jsx'
import { CameraProvider } from './environment/CameraContext.jsx'

const MainStage = React.lazy(() => import('./mainStage/MainStage.jsx'))

export default function Experience() {
  return (
    <GlobalStateProvider>
        <Background/>
        <MainNav />
        <CameraProvider>
          <MainStage/>
        </CameraProvider>   
    </GlobalStateProvider>
  );
}