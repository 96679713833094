export const submitForm = async (formData, setConfirmationVisible, formKeepUrl) => {
    try {
        const formBody = new URLSearchParams(formData).toString();

        const response = await fetch(formKeepUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: formBody,
        });

        if (response.ok) {
            setConfirmationVisible(true);
        } else {
            console.error('Submission failed with status:', response.status, response.statusText);
            alert("Hmm. There was an issue submitting the form. Please check your information and try again.");
        }
    } catch (error) {
        console.error("Error durng submissioin:", error);
        alert("Oh no. There was a problem submitting your form. Please try again.");
    }
};
