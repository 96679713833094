// CameraContext.jsx
import React, { createContext, useContext, useState } from 'react';

const CameraContext = createContext();

export const CameraProvider = ({ children }) => {
    const [isLerping, setIsLerping] = useState(false);
    const [targetPath, setTargetPath] = useState(null);

    const startLerp = (path) => {
        setIsLerping(false); // Reset lerping to allow restart
        setTimeout(() => {
            setTargetPath(path);
            setIsLerping(true); // Trigger the lerp animation
        }, 10); // Small delay to ensure reset is complete before restarting
    };

    const stopLerp = () => setIsLerping(false);

    return (
        <CameraContext.Provider value={{ isLerping, startLerp, stopLerp, targetPath }}>
            {children}
        </CameraContext.Provider>
    );
};

export const useCameraContext = () => useContext(CameraContext);
