import '../../../styles/apps/photos.css'
import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../app elements/AppHeader.jsx'
import CloseApp from '../app elements/CloseApp.jsx'

const imageData = [
    { src: './mobileAssets/photos/photo3.png', title: 'Whistler, Canada', year: '2007', description: 'First time skiing!', alt: 'Jeffrey skiing in Canada' },
    { src: './mobileAssets/photos/photo2.png', title: 'Ephesus, Turkey', year: '2009', description: 'Roman ruins of an ampitheater', alt: 'Jeffrey selfie in Turkey' },
    { src: './mobileAssets/photos/photo4.png', title: 'Turkey', year: '2009', description: 'Riding a camel', alt:'Jeffrey riding a camel in Turkey' },    
    { src: './mobileAssets/photos/photo5.png', title: 'Stonehenge', year: '2013', description: 'Selfie with the rocks!', alt:'Jeffrey at Stonehenge' },
    { src: './mobileAssets/photos/photo11.png', title: 'Tough Mudder', year: '2016', description: '25 miltary-style obstacles over 10 miles', alt:'Jeffrey completing a tough mudder event.' },
    { src: './mobileAssets/photos/photo6.png', title: 'Big Ben', year: '2013', description: 'Anyone know what time it is?', alt:'Jeffrey looking at watch infront of Big Ben in London' },
    { src: './mobileAssets/photos/photo7.png', title: 'Mexico', year: '2018', description: 'Puerto Vallarta is a fun place.', alt:'Puerto Vallarta Selfie on the beach' },
    { src: './mobileAssets/photos/photo1.png', title: 'Sunrise', year: '2018', description: 'View of Lake Michigan', alt: 'Sunrise view of Lake Michigan' },
    { src: './mobileAssets/photos/photo10.png', title: 'Ersan Topcoglu', year: '1985 - 2019', description: 'Thankful for the great adventures.', alt: 'Jeffrey and his best friend Ersan' },
    { src: './mobileAssets/photos/photo8.png', title: 'Las Vegas', year: '2023', description: 'Welcome to Vegas!', alt:'Jeffrey infront of the Welcome to Vegas sign' },
    { src: './mobileAssets/photos/photo9.png', title: 'Mom and Dad', year: '2023', description: 'Vacation to New York City.', alt: 'Jeffreys parents in New York City' },
    { src: './mobileAssets/photos/photo12.png', title: 'Japan', year: '2024', description: 'The Kinkaku-ji in Kyoto.', alt: 'Jeffrey infront of the Kinkaku-Ji in Japan' },
];

export default function Photos() {
    const navigate = useNavigate();
    const [currentImageIndex, setCurrentImageIndex] = useState(null);
    const touchStartX = useRef(null);
    const touchEndX = useRef(null);

    const showNextImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex < imageData.length - 1 ? prevIndex + 1 : 0
        );
    };

    const showPrevImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : imageData.length - 1
        );
    };

    const handleTouchStart = (e) => {
        touchStartX.current = e.touches[0].clientX;
    };

    const handleTouchMove = (e) => {
        touchEndX.current = e.touches[0].clientX;
    };

    const handleTouchEnd = () => {
        if (touchStartX.current && touchEndX.current) {
            const touchDiff = touchStartX.current - touchEndX.current;
            if (touchDiff > 50) {
                // Swiped left
                showNextImage();
            } else if (touchDiff < -50) {
                // Swiped right
                showPrevImage();
            }
        }
        touchStartX.current = null;
        touchEndX.current = null;
    };

    const closeImageView = () => {
        setCurrentImageIndex(null);
    };

    return (
        <div className='app-container'>
            <header className="app-header">
                <Header title="Photos" />
                <CloseApp navigate={navigate} />
            </header>
            <section className="app-contents">
                <div
                    className="photo-grid"
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    {imageData.map((image, index) => (
                        <img
                            key={index}
                            src={image.src}
                            alt={image.alt}
                            onClick={() => setCurrentImageIndex(index)}
                        />
                    ))}
                </div>
            </section>
            {currentImageIndex !== null && (
                <section id="full-image-view-container">
                    <button id="close-bttn" onClick={closeImageView}>X</button>
                    <picture>
                        <source srcSet={`${imageData[currentImageIndex].src.slice(0, -4)}.webp`} type="image/webp" />
                        <img
                            src={imageData[currentImageIndex].src}
                            alt={imageData[currentImageIndex].alt}
                        />
                    </picture>
                    <section id="image-info">
                        <h2>{imageData[currentImageIndex].title}</h2>
                        <p>{imageData[currentImageIndex].year}</p>
                        <p>{imageData[currentImageIndex].description}</p>
                    </section>
                    <button id="prev-bttn" onClick={showPrevImage}>&lt;</button>
                    <button id="next-bttn" onClick={showNextImage}>&gt;</button> 
                </section>
            )}
        </div>
    );
}