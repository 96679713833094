import React from 'react';
import '../../styles/confirmationPopupStyles.css';

export default function EmailConfirmationPopup({ message, onClose }) {
    return (
        <div className="confirmation-popup">
            <p>{message}</p>
            <button onClick={onClose} className="close-button">Close</button>
        </div>
    );
}
